<div class="payment-loading" *ngIf="!payment && !errorMessage">
  <app-spinner></app-spinner>
  <div class="message">Loading...</div>
</div>

<div class="error-msg" *ngIf="errorMessage">
  <div class="message">{{ errorMessage }}</div>
</div>

<div class="paid-msg" *ngIf="payment && payment.complete && payment.success">
  <div class="message">Payment already completed.</div>
  <div class="sub-message">
    You may now <a (click)="closeWindow()">close this window</a>.
  </div>
</div>

<div class="payment-complete-overlay" *ngIf="paymentComplete">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
    />
  </svg>
  <div class="message" *ngIf="payment.type == 'payment'">Payment complete.</div>
  <div class="message" *ngIf="payment.type == 'tokenization'">
    Credit card saved.
  </div>
  <div class="sub-message">
    You may now <a (click)="closeWindow()">close this window</a>.
  </div>
</div>

<div
  class="container"
  *ngIf="payment && (!payment.complete || !payment.success)"
  [ngClass]="{ 'payment-complete': paymentComplete }"
>
  <div class="header">
    <div class="logo">
      <img
        [src]="payment.merchant.logoUrl"
        [style.height.px]="payment.merchant.logoHeightPx"
      />
    </div>
    <div class="payment-info">
      <div class="payment-amount" *ngIf="payment.type == 'payment'">
        {{ payment.amount | currency }}
      </div>
      <div class="payment-description">{{ payment.description }}</div>
    </div>
  </div>

  <div class="card-brands">
    <div class="card-brand"><img src="assets/card-logos/mc.svg" /></div>
    <div class="card-brand"><img src="assets/card-logos/visa.svg" /></div>
    <div class="card-brand amex">
      <img src="assets/card-logos/amex.svg" />
    </div>
  </div>

  <form class="payment-form">
    <div class="col">
      <label for="ccname">Name</label>
      <input
        id="html"
        type="text"
        (keyup)="updateValid()"
        [(ngModel)]="cc.name"
        placeholder="Name"
        name="ccname"
        autocomplete="cc-name"
      />
    </div>
    <div class="col">
      <label for="cardnumber">Card Number</label>
      <input
        type="text"
        pattern="\d*"
        [(ngModel)]="cc.pan"
        (keyup)="creditCardKeyup($event); updateValid()"
        placeholder="XXXX XXXX XXXX XXXX"
        maxlength="23"
        name="cardnumber"
        autocomplete="cc-number"
      />
    </div>

    <div class="flex-container">
      <div class="col">
        <label for="exp-date">Expiry</label>
        <input
          type="text"
          pattern="\d*"
          [(ngModel)]="cc.expiry"
          (keyup)="expiryKeyup($event); updateValid()"
          placeholder="MM / YY"
          maxlength="7"
          name="exp-date"
          autocomplete="cc-exp"
        />
      </div>
      <div class="col" *ngIf="payment.type == 'payment'">
        <label for="cvc">CVV</label>
        <input
          type="text"
          pattern="\d*"
          (keyup)="updateValid()"
          [(ngModel)]="cc.cvn"
          placeholder="CVV"
          name="cvc"
          autocomplete="cc-csc"
        />
      </div>
    </div>

    <div
      class="button-loading-container"
      [ngClass]="{ loading: processingPayment, invalid: !isValid }"
    >
      <div class="button-container">
        <button
          class="button"
          (click)="pay()"
          *ngIf="payment.type == 'payment'"
        >
          Pay
        </button>
        <button
          class="button"
          (click)="pay()"
          *ngIf="payment.type == 'tokenization'"
        >
          Save Card
        </button>
      </div>
      <div class="loading-container">
        <app-loading-dots></app-loading-dots>
      </div>
    </div>
    <div class="payment-error" [ngClass]="{ show: paymentError }">
      {{ paymentError }}
    </div>
  </form>
</div>
<div class="powered-by">
  Powered by
  <img src="assets/viva-pay-logos/logo-black.svg" />
</div>
