import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentsService } from 'src/app/services/payments/payments.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pay-with-viva-pay',
  templateUrl: './pay-with-viva-pay.component.html',
  styleUrls: ['./pay-with-viva-pay.component.scss'],
})
export class PayWithVivaPayComponent implements OnInit {
  id;
  constructor(public route: ActivatedRoute) {}

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  async done(e) {
    console.log(e);
  }
}
