import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(private httpClient: HttpClient) {}

  async getCardRequest(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = environment.paymentsApiBasePath + '/card-request/' + id;

      this.httpClient.get<any>(url).subscribe(resolve, reject);
    });
  }

  async completeCardRequest(id: string, card: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const url =
        environment.paymentsApiBasePath + '/card-request/' + id + '/complete';

      this.httpClient.post<any>(url, card).subscribe(resolve, resolve);
    });
  }
}
