<div
  class="pay-button"
  [ngClass]="{ complete: payment && payment.complete }"
  (click)="pay()"
>
  <div class="loading" *ngIf="!payment">
    <app-loading-dots color="#262262"></app-loading-dots>
  </div>

  <div
    class="to-pay"
    *ngIf="payment && payment.type == 'tokenization' && !payment.complete"
  >
    <div class="pay-with">
      Pay <br />
      with
    </div>
  </div>

  <div
    class="to-pay"
    *ngIf="payment && payment.type == 'payment' && !payment.complete"
  >
    <div class="pay-amount">{{ payment.amount | currency }}</div>
    <div class="pay-with">Pay<br />with</div>
  </div>

  <div class="paid" *ngIf="payment && payment.complete">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
      />
    </svg>
    <div class="paid-message">Done</div>
  </div>

  <img src="/assets/viva-pay-logos/logo.svg" />
</div>
