import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentsService } from 'src/app/services/payments/payments.service';

@Component({
  selector: 'app-payment-box',
  templateUrl: './payment-box.component.html',
  styleUrls: ['./payment-box.component.scss'],
})
export class PaymentBoxComponent implements OnInit {
  id: string;
  errorMessage: string;
  payment: any;
  public cardInputLength = 0;
  public expiryInputLength = 0;

  processingPayment = false;
  paymentComplete = false;
  paymentError = '';

  isValid = false;

  forceWaitMs = 2000; // Wait before showing payment form

  public cc = {
    name: '',
    pan: '',
    cvn: '',
    expiry: '',
  };

  constructor(
    private route: ActivatedRoute,
    private paymentsService: PaymentsService
  ) {}

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    const startTime = new Date().getTime();

    try {
      const payment = await this.paymentsService.getCardRequest(this.id);
      const timeElapsed = new Date().getTime() - startTime;

      setTimeout(() => {
        this.payment = payment;
      }, this.forceWaitMs - timeElapsed);
    } catch (e) {
      console.log(e);
      if (e.status == 404) {
        this.errorMessage = 'Payment request expired.';
      }
    }
  }

  async pay() {
    this.processingPayment = true;
    this.paymentError = '';

    if (!this.cc.name || this.cc.name == '') {
      this.paymentError = 'No credit card name provided!';
      this.processingPayment = false;
      return;
    }

    const pan = this.replaceAll(this.cc.pan, ' ', '');

    if (!this.cc.pan || pan == '') {
      this.paymentError = 'Invalid credit card number!';
      this.processingPayment = false;
      return;
    }

    let exp = this.replaceAll(this.cc.expiry, ' ', '').split('/');

    if (exp.length != 2) {
      this.paymentError = 'Invalid expiry! Format: MM / YY';
      this.processingPayment = false;
      return;
    }

    if (parseInt(exp[0]) < 1 || parseInt(exp[0]) > 12) {
      this.paymentError = 'Invalid expiry month!';
      this.processingPayment = false;
      return;
    }

    const expMonth = parseInt(exp[0]);

    if (parseInt(exp[1]) < 0) {
      this.paymentError = 'Invalid expiry year!';
      this.processingPayment = false;
      return;
    }

    let expYear = parseInt(exp[1]);
    if (parseInt(exp[1]) > 2000) {
      expYear = expYear - 2000;
    }

    if (this.payment.type == 'payment' && (!this.cc.cvn || this.cc.cvn == '')) {
      this.paymentError = 'Invalid CVV!';
      this.processingPayment = false;
      return;
    }

    const card = {
      name: this.cc.name,
      pan: pan,
      expiryMonth: expMonth,
      expiryYear: expYear,
      cvn: this.cc.cvn,
    };

    const startTime = new Date().getTime();

    const response = await this.paymentsService.completeCardRequest(
      this.id,
      card
    );
    const timeElapsed = new Date().getTime() - startTime;
    setTimeout(() => {
      console.log(response);
      if (!response.success) {
        if (
          response.error &&
          (typeof response.error === 'string' ||
            response.error instanceof String)
        ) {
          if (response.error.includes('body/name')) {
            this.paymentError = 'Please check name.';
          } else {
            this.paymentError = 'Server error.';
          }
        } else if (response.error.success === false) {
          this.paymentError = response.error.message;
        } else {
          this.paymentError = response.message;
        }
      } else {
        this.paymentComplete = true;
      }
      this.processingPayment = false;
    }, this.forceWaitMs - timeElapsed);
  }

  creditCardKeyup(e) {
    let inputValue = e.target.value;
    let newString = '';
    for (let i = 0; i < inputValue.length; i++) {
      const regex = new RegExp('[0-9]');
      if (!regex.test(inputValue[i])) continue;

      newString += inputValue[i];
      const currentLength = this.replaceAll(newString, ' ', '').length;
      if (currentLength > 0 && currentLength % 4 == 0) {
        newString += ' ';
      }
    }

    if (newString[newString.length - 1] == ' ') {
      newString = newString.substring(0, newString.length - 1);
    }

    this.cardInputLength = inputValue.length;
    e.target.value = newString;
    this.cc.pan = newString;
  }

  expiryKeyup(e) {
    let inputValue = e.target.value;

    e.target.value = this.replaceAll(e.target.value, '//', '/');
    this.cc.expiry = e.target.value;

    if (inputValue.length > 2 || inputValue.length == this.expiryInputLength)
      return;

    const regex = new RegExp('[0-9][0-9]$');
    if (regex.test(inputValue)) {
      e.target.value = inputValue + '/';
      this.cc.expiry = e.target.value;
    }
    this.expiryInputLength = inputValue.length;
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  updateValid() {
    this.isValid =
      this.cc.name != '' &&
      this.cc.pan != '' &&
      this.cc.expiry != '' &&
      (this.payment.type == 'tokenization' || this.cc.cvn != '');
  }

  closeWindow() {
    window.close();
  }
}
