import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PaymentBoxComponent } from './components/payment-box/payment-box.component';
import { PoweredByPageComponent } from './components/powered-by-page/powered-by-page.component';
import { PayWithVivaPayComponent } from './components/pay-with-viva-pay/pay-with-viva-pay.component';
import { HttpClientModule } from '@angular/common/http';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LoadingDotsComponent } from './components/loading-dots/loading-dots.component';
import { FormsModule } from '@angular/forms';
import { PayButtonComponent } from './components/pay-button/pay-button.component';
import { DefaultComponent } from './components/default/default.component';

@NgModule({
  declarations: [
    AppComponent,
    PaymentBoxComponent,
    PoweredByPageComponent,
    PayWithVivaPayComponent,
    SpinnerComponent,
    LoadingDotsComponent,
    PayButtonComponent,
    DefaultComponent,
  ],
  imports: [HttpClientModule, BrowserModule, AppRoutingModule, FormsModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
