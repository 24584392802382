import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './components/default/default.component';
import { PayWithVivaPayComponent } from './components/pay-with-viva-pay/pay-with-viva-pay.component';
import { PaymentBoxComponent } from './components/payment-box/payment-box.component';

const routes: Routes = [
  { path: 'p/:id', component: PaymentBoxComponent },
  { path: 't/:id', component: PayWithVivaPayComponent },
  { path: '**', component: DefaultComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
