import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PaymentsService } from 'src/app/services/payments/payments.service';

@Component({
  selector: 'app-pay-button',
  templateUrl: './pay-button.component.html',
  styleUrls: ['./pay-button.component.scss'],
})
export class PayButtonComponent implements OnInit {
  @Input('request-id') public requestId: string;
  @Output('complete') onComplete = new EventEmitter<any>();
  payment;
  checkInterval;
  lastPaymentAttemptsCount = 0;

  constructor(public paymentsService: PaymentsService) {}

  async ngOnInit() {
    this.checkInterval = setInterval(() => {
      this.fetchPayment();
    }, 5000);
    this.fetchPayment();
  }

  async fetchPayment() {
    this.payment = await this.paymentsService.getCardRequest(this.requestId);

    if (this.payment.complete) {
      clearInterval(this.checkInterval);
      this.onComplete.emit(this.payment);
      return;
    }

    if (this.lastPaymentAttemptsCount != this.payment.paymentAttempts) {
      this.onComplete.emit(this.payment);
      this.lastPaymentAttemptsCount = this.payment.paymentAttempts;
      return;
    }
  }

  pay() {
    window.open(
      '/p/' + this.requestId,
      'winname',
      'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=600,height=700'
    );
  }
}
